<template>
  <div id="form-header">
    <order-information-view
      class="mb-1"
      :quote="{
        ...order,
        supplier: order.customer,
        dispatch_contact: order.dispatch_contact && {...order.dispatch_contact,firstname: order.dispatch_contact.first_name,lastname: order.dispatch_contact.last_name,phone: order.dispatch_contact.phone_one},
        billing_address: order.return_warehouse
      }"
    />
    <stock-items
      class="mb-1"
      :order-items="orderItems"
      is-sub-rent
    />
    <attachment
      class="mb-1"
      :accept-extension-array="['.jpg', '.jpeg', '.png', '.tif', '.pdf', '.bmp']"
    />
    <notes-and-totals />
    <form-bottom
      :form-ref="$refs"
      :buttons-list="invoiceProgressButtons"
    />
  </div>
</template>

<script>
import OrderInformationView
from '@/views/main/orders/view/purchase/components/create/rfq-for-supplier/OrderInformation.vue'
import StockItems from '@/views/main/orders/view/purchase/components/create/purchase-order-for-supplier/StockItems.vue'
import Attachment from '@/views/main/orders/view/sub-rent/components/approve/Attachment.vue'
import NotesAndTotals from '../../components/approve/NotesAndTotals.vue'
import FormBottom from '../../components/form-submit-buttons/FormBottom.vue'
import config from '../../config'
import formBtnConfig from '../../components/form-submit-buttons/libs'

export default {
  name: 'DirectInvoice',
  components: {
    Attachment,
    StockItems,
    NotesAndTotals,
    OrderInformationView,
    FormBottom,
  },
  computed: {
    order() {
      return this.$store.state[this.MODULE_NAME].order
    },
    orderItems() {
      return this.order.order_items.map(i => ({ ...i, sku: i.product.sku, name: i.product.name }))
    },
  },
  setup() {
    const { MODULE_NAME } = config()
    const {
      invoiceProgressButtons,
    } = formBtnConfig()

    return {
      MODULE_NAME,
      invoiceProgressButtons,
    }
  },
}
</script>
