<template>
  <div class="d-flex mt-2 pb-1 justify-content-between">
    <div>
      <b-button
        class="cancelBtn font-medium-1 font-weight-bolder"
        variant="outline-primary"
        @click="$router.back()"
      >
        <feather-icon
          icon="LCancelIcon"
          size="16"
        />
        {{ $t('Cancel') }}
      </b-button>
    </div>
    <div class="d-flex">
      <b-overlay
        v-for="(item) in isCreateForm ? orderCreate : buttonsList"
        :key="item.title"
        :show="item.isLoading"
        rounded
        opacity="0.6"
        spinner-small
        :spinner-variant="Object.hasOwn(item, 'color') ? item.color : 'secondary'"
        class="d-inline-block ml-1"
      >
        <b-button
          :variant="Object.hasOwn(item, 'color') ? item.color : 'secondary'"
          class="font-medium-1 font-weight-bold px-3"
          :disabled="isDisabled(item)"
          @click="handleSubmit(item)"
        >
          <feather-icon
            :icon="Object.hasOwn(item, 'icon') ? item.icon : ''"
            size="16"
          />
          {{ item.title }}
        </b-button>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import { BButton, BOverlay } from 'bootstrap-vue'
import jsonToFormData from '@/libs/jsonToFormData'
import { mapGetters } from 'vuex'
import { printContent } from '@/views/main/orders/components/helpers'
import { scrollToError } from '@core/utils/utils'
import quoteStatusBarConfig from '@/views/main/orders/config'
import config from './libs'
import mainConfig from '../../config'

export default {
  name: 'FormBottom',
  components: {
    BOverlay,
    BButton,
  },
  props: {
    formRef: {
      type: Object,
      required: true,
    },
    isCreateForm: {
      type: Boolean,
      default: false,
    },
    buttonsList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      id: this.$route.params.id,
    }
  },
  computed: {
    ...mapGetters('sub-rent', ['getState']),
    order() {
      return this.$store.state[this.MODULE_NAME].order
    },
    isVoidOrder() {
      return this.order?.status === this.ORDERS_STATUS_VOID.status
    },
    isReadyInvoice() {
      return this.order?.status === this.ORDERS_STATUS_READY.status && this.order?.state >= 2
    },
  },
  methods: {
    isDisabled(item) {
      return this.isLoading || (Object.hasOwn(item, 'disabled') && item.disabled) || this.isVoidOrder || (item.actionKey !== 'print' && this.isReadyInvoice)
    },
    // eslint-disable-next-line consistent-return
    handleSubmit(item) {
      const { actionKey, beforeProcessUrl } = item
      if (actionKey === 'print') {
        return this.printContent()
      }
      // eslint-disable-next-line consistent-return
      if (!actionKey && !beforeProcessUrl) return

      if (beforeProcessUrl) {
        return this.$router.push({ name: beforeProcessUrl, params: { id: this.id } })
      } if (this.formRef.subRentQuoteForm) {
        this.formRef.subRentQuoteForm.validate().then(success => {
          if (success) {
            this.submitLogic(item)
          } else {
            scrollToError(this, this.formRef.subRentQuoteForm)
          }
        })
      } else {
        this.submitLogic(item)
      }
    },
    submitLogic(item) {
      const { actionKey, afterFinishedProcessUrl, retryActionKey } = item
      this.isLoading = true

      // if (retryActionKey) {
      //   return Promise.all(actionKey.map(key => this.submitLogic({ ...item, actionKey: key })))
      // }

      const body = jsonToFormData({
        ...this.getState,
        action: actionKey,
        id: this.id,
        _method: this.isCreateForm ? undefined : 'PUT',
      })
      // eslint-disable-next-line no-nested-ternary
      return this.sendNotification(this, body, `${this.MODULE_NAME}/${this.isCreateForm ? 'create' : 'updateSubRental'}`)
        .then(response => {
          const { data } = response.data
          if (retryActionKey) {
            return this.submitLogic({ ...item, actionKey: retryActionKey, retryActionKey: undefined })
          }
          this.redirect(afterFinishedProcessUrl, data.id)
          this.$store.commit(`${this.MODULE_NAME}/SET_ON_ERROR`, {})
        })
        .catch(err => {
          this.errorNotification(this, err)
          this.$store.commit(`${this.MODULE_NAME}/SET_ON_ERROR`, err.response?.data)
          const element = document.getElementById('form-header')
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' })
            element.focus()
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    redirect(pathName, id) {
      if (this.isCreateForm) {
        this.$router.push({ name: 'sub-rent-request-for-quote', params: { id } })
      } else if (pathName) {
        this.$router.push({ name: pathName, params: { id } })
      }
      if (!this.isCreateForm) {
        this.getSubRentItem(this)
      }
    },
  },
  setup() {
    const {
      MODULE_NAME, model, emptyCallback, getSubRentItem,
    } = mainConfig()
    const { ORDERS_STATUS_VOID, ORDERS_STATUS_READY } = quoteStatusBarConfig()
    const { orderCreate } = config()
    return {
      MODULE_NAME,
      model,
      emptyCallback,
      printContent,
      getSubRentItem,
      ORDERS_STATUS_VOID,
      ORDERS_STATUS_READY,
      orderCreate,
    }
  },
}
</script>
