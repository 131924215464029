export default function config() {
  // draft_progress
  // draft_ready
  // draft_void
  // send_mail
  // approve_progress
  // approve_ready
  // approve_void
  // invoice_progress
  // invoice_attention
  // invoice_warning
  // invoice_ready
  const orderCreate = [
    {
      title: 'Void',
      color: 'danger',
      disabled: true,
    },
    {
      title: 'Print',
      icon: 'LPrintIcon',
      disabled: true,
    },
    {
      title: 'Save as Draft',
      actionKey: 'draft_progress',
    },
    {
      title: 'Send by Email',
      actionKey: 'draft_ready',
      disabled: true,
    },
    {
      title: 'Quote Received',
      actionKey: 'draft_ready',
      color: 'success',
      disabled: true,
    },
  ]
  const orderInitialStateButtons = [
    {
      title: 'Void',
      color: 'danger',
      actionKey: 'draft_void',
    },
    {
      title: 'Print',
      icon: 'LPrintIcon',
    },
    {
      title: 'Save as Draft',
      actionKey: 'draft_ready',
      afterFinishedProcessUrl: 'sub-rent-request-for-quote-ready',
    },
    {
      title: 'Send by Email',
      actionKey: 'draft_ready',
      retryActionKey: 'send_mail',
      afterFinishedProcessUrl: 'sub-rent-approve',
    },
    {
      title: 'Quote Received',
      actionKey: 'draft_ready',
      retryActionKey: 'approve_progress',
      color: 'success',
      afterFinishedProcessUrl: 'sub-rent-approve',
    },
  ]

  const readyDraftButtons = [
    {
      title: 'Void',
      actionKey: 'draft_void',
      color: 'danger',
    },
    {
      title: 'Change RFQ',
      beforeProcessUrl: 'sub-rent-request-for-quote',
      icon: 'LLoadIcon',
    },
    {
      title: 'Print',
      actionKey: 'print',
      color: '',
      icon: 'LPrintIcon',
    },
    {
      title: 'Send Again',
      actionKey: 'send_mail',
      afterFinishedProcessUrl: 'sub-rent-approve',
    },
    {
      title: 'Quote Received',
      actionKey: 'approve_progress',
      color: 'success',
      afterFinishedProcessUrl: 'sub-rent-approve',
    },
  ]

  const approveProgressButtons = [
    {
      title: 'Reject Quote',
      actionKey: 'approve_void',
      color: 'danger',
    },
    {
      title: 'Back to RFQ',
      actionKey: 'draft_ready',
      icon: 'LLoadIcon',
    },
    {
      title: 'Send PO',
      actionKey: 'approve_ready',
      retryActionKey: 'invoice_progress',
      afterFinishedProcessUrl: 'sub-rent-invoice',
    },
    {
      title: 'Accept Quote',
      actionKey: 'approve_ready',
      retryActionKey: 'invoice_progress',
      afterFinishedProcessUrl: 'sub-rent-invoice',
      icon: 'LSignatureIconFill',
      color: 'success',
    },
  ]

  const invoiceProgressButtons = [
    {
      title: 'Print',
      actionKey: 'print',
      color: '',
      icon: 'LPrintIcon',
    },
    {
      title: 'Upload Invoice',
      actionKey: 'invoice_ready',
      icon: 'LCreditCardIconFill',
      color: 'success',
    },
  ]

  return {
    orderInitialStateButtons,
    readyDraftButtons,
    approveProgressButtons,
    invoiceProgressButtons,
    orderCreate,
  }
}
