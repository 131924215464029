var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-wrapper"},[_c('h3',{staticClass:"font-weight-bolder mb-1",staticStyle:{"color":"#646464"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.STOCK_ITEMS_TITLE))+" ")]),_c('b-table',{staticClass:"purchase-order-stock-items",attrs:{"items":_vm.products,"fields":_vm.StockItemTableColumns,"show-empty":true,"empty-text":'None found',"foot-clone":""},scopedSlots:_vm._u([{key:"cell(action)",fn:function(ref){
var ref_item = ref.item;
var id = ref_item.id;
var product_id = ref_item.product_id;
return [_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"LStockEyeIcon","size":"32"},on:{"click":function($event){return _vm.gotoNewPage({name: 'home-catalog-general-info', params: { id: _vm.isSubRent ? product_id : id } }, $event, true)}}})]}},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('state-of-asset',{attrs:{"data":item,"with-label":false}}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t(item.name)))])],1)]}},{key:"cell(price)",fn:function(ref){
var price = ref.item.price;
return [_vm._v(" "+_vm._s(_vm.formatNumberToCurrencyView(price))+" ")]}},{key:"cell(discount)",fn:function(ref){
var discount = ref.item.discount;
return [_vm._v(" "+_vm._s(_vm.formatNumberToCurrencyView(discount))+" ")]}},{key:"cell(amount)",fn:function(ref){
var amount = ref.item.amount;
return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(amount)+" ")])]}},{key:"cell(subtotal)",fn:function(ref){
var ref_item = ref.item;
var discount = ref_item.discount;
var price = ref_item.price;
var amount = ref_item.amount;
return [_vm._v(" "+_vm._s(_vm.formatNumberToCurrencyView((price *amount) - discount ))+" ")]}},{key:"cell(taxes)",fn:function(ref){
var ref_item = ref.item;
var price = ref_item.price;
var tax = ref_item.tax;
var amount = ref_item.amount;
return [_vm._v(" "+_vm._s(_vm.formatNumberToCurrencyView((price *amount) * tax / 100))+" ")]}},{key:"cell(total)",fn:function(ref){
var ref_item = ref.item;
var discount = ref_item.discount;
var price = ref_item.price;
var tax = ref_item.tax;
var taxes = ref_item.taxes;
var amount = ref_item.amount;
return [_c('div',{staticClass:"text-right",staticStyle:{"padding-right":"6px"}},[_vm._v(" "+_vm._s(_vm.formatNumberToCurrencyView((price * amount) + Number(taxes) - discount))+" ")])]}},{key:"foot(sku)",fn:function(){return [_vm._v(" "+_vm._s(' ')+" ")]},proxy:true},{key:"foot(price)",fn:function(){return [_vm._v(" "+_vm._s(' ')+" ")]},proxy:true},{key:"foot(name)",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t(_vm.TOTALS_TOTAL_PRICE_TITLE))+"S ")]},proxy:true},{key:"foot(amount)",fn:function(){return [_vm._v(" "+_vm._s(_vm.getTotalOF('amount'))+" ")]},proxy:true},{key:"foot(discount)",fn:function(){return [_c('span')]},proxy:true},{key:"foot(subtotal)",fn:function(){return [_vm._v(" "+_vm._s(_vm.formatNumberToCurrencyView(_vm.totalSumOfSubtotals))+" ")]},proxy:true},{key:"foot(taxes)",fn:function(){return [_vm._v(" "+_vm._s(_vm.formatNumberToCurrencyView(_vm.totalSumOfTaxes))+" ")]},proxy:true},{key:"foot(total)",fn:function(){return [_vm._v(" "+_vm._s(_vm.formatNumberToCurrencyView(_vm.totalSumOfItem))+" ")]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }